import { useState } from "react";
import { Row, Col, Drawer } from "antd";
import { withTranslation, TFunction } from "react-i18next";
import Container from "../../common/Container";
import { SvgIcon } from "../../common/SvgIcon";
import { Button } from "../../common/Button";
import {
  HeaderSection,
  LogoContainer,
  Burger,
  NotHidden,
  Menu,
  CustomNavLinkSmall,
  Label,
  Outline,
  Span,
  LogoHeader,
} from "./styles";
import { CallToAction } from "../CallToAction";

const Header = ({ t }: { t: TFunction }) => {
  const [visible, setVisibility] = useState(false);

  const toggleButton = () => {
    setVisibility(!visible);
  };

  const MenuItem = () => {
    return (
      <>
        <CallToAction />
      </>
    );
  };

  return (
    <HeaderSection>
      <Container>
        <Row justify="space-between">
          <LogoContainer to="/" aria-label="homepage">
            <LogoHeader src="img/svg/logo.png" />
          </LogoContainer>
          <NotHidden>

            <div style={{ display: "flex", alignItems: "center", gap: "15px"}}>
              <div>
                <a href="tel:+48534454659" style={{ fontSize: "1.5rem" }}>
                  +48 534 454 659
                </a>
              </div>

              <MenuItem />
            </div>

          </NotHidden>

          
          <Burger onClick={toggleButton}>
            <div>
              <a href="tel:+48534454659" style={{ fontSize: "1.2rem" }}>
                +48 534 454 659
              </a>
            </div>

            <Outline />
          </Burger>
        </Row>
        <Drawer closable={false} open={visible} onClose={toggleButton}>
          <Col style={{ marginBottom: "2.5rem" }}>
            <Label onClick={toggleButton}>
              <Col span={12}>
                <Menu>Menu</Menu>
              </Col>
              <Col span={12}>
                <Outline />
              </Col>
            </Label>
          </Col>
          <MenuItem />
        </Drawer>
      </Container>
    </HeaderSection>
  );
};

export default withTranslation()(Header);
