import { Button } from "../common/Button";
import { openChatBot } from "../voiceflow/voiceflow";

export function CallToAction () {
    return (
        <div style={{ width: "180px" }}>
        <Button onClick={openChatBot}>Porozmawiajmy</Button>
      </div>
    )
}
